// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.define-palette(mat.$blue-palette);
$theme-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

// typography
$desktop-typography: mat.define-typography-config(
  $headline: mat.define-typography-level(60px, 1em, 400),
  $body-1: mat.define-typography-level(24px, 1em, 400),

);

$mobile-typography: mat.define-typography-config(
  $headline: mat.define-typography-level(30px, 1em, 400),
  $body-1: mat.define-typography-level(16px, 1em, 400),
);

@include mat.core($desktop-typography);

@media only screen and (max-width: 800px) {
  @include mat.core($mobile-typography);
}
