/* You can add global styles to this file, and also import other style files */

:root {
  --color-text-dark: #4a4a4a;
  --color-text-light: #ffffff;
  --color-text-gray: #b0b0b0;
  --erbo-blue: #007bd8;
}

html,
body {
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-size: 80px;
  background-color: #fafafa;
}

/*
 * Typography
 */
/* Declare text styles */
h1,
h2,
h3,
p {
  /* Font minimum, preferred and maximum value */
  font-size: clamp(var(--min), var(--val), var(--max));
}

/* Font size variables */
h1 {
  --min: 2em; /* minimum value */
  --val: 5vw; /* preferred value = 5% viewport width */
  --max: 3em; /* maximum value */
}
h2 {
  --min: 1.5em; /* minimum value */
  --val: 4vw; /* preferred value = 4% viewport width */
  --max: 2.25em; /* maximum value */
}
h3 {
  --min: 1.25em; /* minimum value */
  --val: 4vw; /* preferred value = 4% viewport width */
  --max: 2em; /* maximum value */
}
p {
  --min: 1em; /* minimum value */
  --val: 2.5vw; /* preferred value = 2.5% viewport width */
  --max: 1.5em; /* maximum value */
}

mat-checkbox {
  label.mat-checkbox-layout {
    white-space: normal;
  }
}

.erbo-panel {
  @media only screen and (max-width: 600px) {
    height: 100vh;
    width: 100vw;
    max-width: 100vw !important;
  }

  mat-dialog-container {
    padding: 0;
  }
}

.snowflake {
  z-index: 666;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  opacity: 0.8;
  pointer-events: none;
}
